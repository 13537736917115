import React, { useState } from 'react';
import styled from '@emotion/styled';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';
import { AutoCompleteValues } from 'types/AutoCompleteValues';
import { useTranslation } from "react-i18next";


const StyledFormControl = styled(FormControl)`
  min-width: 99%;
  max-width: 200px;
  @media (min-width: 768px) {
    max-width: 200px;
  }
`;
type DropdownProps = {
  options: AutoCompleteValues[],
  label: string,
  onChange: (selectedValues: string) => void,
  selectedValue: string,
  required: boolean,
  includeIdInLabel?: boolean
};
const MuiDropdown = ({
  options,
  label,
  onChange,
  selectedValue,
  required,
  includeIdInLabel = false
}: DropdownProps) => {

  const [value, setValue] = useState(selectedValue || '');
  const [error, setError] = useState(false);
  const [t, ] = useTranslation();
  const handleChange = (event: SelectChangeEvent<string>) => {
    setValue(event.target.value as string);
    onChange(event.target.value as string);   
  };

  return (
    <StyledFormControl  required={required}  error={error}>
      <InputLabel style={{ fontSize: 14 }} size='small' id="select-label">{label}</InputLabel>
      <Select
        id="multiselect"
        multiple={false}
        size='small'
        label={label}
        value={value}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
          <ListItemText
            primary={includeIdInLabel ? `${option.id} ${option.name} `: option.name}
          />
        </MenuItem>
        ))}
      </Select>
      {error && !value.length && (
        <Typography fontSize={10} fontWeight={400} color='red' marginLeft={2} variant="subtitle2">{label + t("isRequiredText")}</Typography>
      )}
    </StyledFormControl>
  );
};

export default MuiDropdown;