import { refreshGridDataAtom } from 'Atoms/WorkAssignmentGridAtoms';
import axios, { AxiosError, AxiosResponse } from "axios";
import { useAtom } from 'jotai';
import { UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query";
import { AutoCompleteValues } from 'types/AutoCompleteValues';
import { WorkAssignmentFilters } from "types/WorkAssignmentFilter";
import Problem from 'types/problem';
import { createQueryParamString } from 'utilities/RequestUtil';
import Config from "../config";
import type { Assignment } from '../types/Assignment';
import type { Operation } from '../types/Operation';
import { QueryParam } from '../types/QueryParam';
import { UserAtom } from 'Atoms/AuthAtoms';
import { companyCodeAtom } from 'Atoms/SettingsAtom';

export const useFetchWorkAssignment = (filters: WorkAssignmentFilters, workOrderNo: string, tabIndex: number): UseQueryResult<Operation[], AxiosError> => {
  const [refreshGridCount] = useAtom(refreshGridDataAtom);
  const [user] = useAtom(UserAtom);
  const [companyCode] = useAtom(companyCodeAtom);
  const queryResult = useQuery<Operation[], AxiosError>(
    ['data', refreshGridCount],
    async () => {
      if (filters && tabIndex === 0) {
        const response = await axios.post(`${Config.baseApiUrl}/operations`, filters);
        return response.data.sort((a: Operation, b: Operation) => new Date(a.scheduledStart).getTime() - new Date(b.scheduledStart).getTime());
      } else if (workOrderNo && tabIndex === 1) {
        const queryParams: QueryParam[] = [
          {
            name: 'workorderno',
            value: workOrderNo
          },
          {
            name: 'companycode',
            value: companyCode
          }
        ];
        const queryString = createQueryParamString(queryParams);
        const response = await axios.get(`${Config.baseApiUrl}/operationsbywo${queryString}`);
        return response.data.sort((a: Operation, b: Operation) => new Date(a.scheduledStart).getTime() - new Date(b.scheduledStart).getTime());
      } else {
        return [];
      }
    },
    {
      enabled: refreshGridCount > 0,
    }
  );

  return queryResult;
};

export const useFetchPlanningPlants = (companyCode: string, options = {}) => {
  const queryParams: QueryParam[] = [
    {
      name: 'companyCode',
      value: companyCode
    }
  ];
  const queryString = createQueryParamString(queryParams);
  return useQuery<AutoCompleteValues[], AxiosError>(["P", companyCode], () =>
    axios.get(`${Config.baseApiUrl}/PlanningPlants${queryString}`).then((resp) => resp.data).catch((error) => {
      console.log(error);
    }),
    {
      ...options
    }
  );
};

export const useFetchPlanningPlantsForCode = (planningPlants: string[]) => {
  const queryParams: QueryParam[] = [
    {
      name: 'PlanningPlants',
      value: planningPlants ?? [],
    }
  ];
  const queryString = createQueryParamString(queryParams);

  return useQuery<AutoCompleteValues[], AxiosError>(['planningPlantsFromCode', planningPlants], () =>
    axios.get(`${Config.baseApiUrl}/PlanningPlantsFromCode${queryString}`).then((resp) => resp.data),
    {
      enabled: planningPlants.length > 0
    },
  );
};

export const useFetchMaintenancePlants = (planningPlants: string[]) => {
  const queryParams: QueryParam[] = [
    {
      name: 'PlanningPlants',
      value: planningPlants ?? [],
    }
  ];
  const queryString = createQueryParamString(queryParams);

  return useQuery<AutoCompleteValues[], AxiosError>(['MaintenancePlant', planningPlants], () =>
    axios.get(`${Config.baseApiUrl}/MaintenancePlants${queryString}`).then((resp) => resp.data),
    {
      enabled: planningPlants.length > 0
    },
  );
};

export const useFetchMaintenancePlantsForCode = (maintenancePlants: string[]) => {
  const queryParams: QueryParam[] = [
    {
      name: 'maintenancePlants',
      value: maintenancePlants ?? [],
    }
  ];
  const queryString = createQueryParamString(queryParams);

  return useQuery<AutoCompleteValues[], AxiosError>(['maintenancePlantsFromCode', maintenancePlants], () =>
    axios.get(`${Config.baseApiUrl}/MaintenancePlantsFromCode${queryString}`).then((resp) => resp.data),
    {
      enabled: maintenancePlants.length > 0
    },
  );
};

export const useFetchWorkCenters = (planningPlants: string[]) => {
  const queryParams: QueryParam[] = [
    {
      name: 'PlanningPlants',
      value: planningPlants ?? [],
    },
    {
      name: 'MaintenancePlants',
      value: [],
    },
  ];
  const queryString = createQueryParamString(queryParams);

  return useQuery<AutoCompleteValues[], AxiosError>(['WorkCenters', planningPlants], () =>
    axios.get(`${Config.baseApiUrl}/WorkCenters${queryString}`).then((resp) => resp.data),
    {
      enabled: (planningPlants.length > 0)
    },
  );
};

export const useFetchWorkCentersFromCode = (workCenters: string[]) => {
  const queryParams: QueryParam[] = [
    {
      name: 'workCenters',
      value: workCenters ?? [],
    }
  ];
  const queryString = createQueryParamString(queryParams);

  const { data, ...rest } = useQuery<AutoCompleteValues[], AxiosError>(['WorkCentersFromCode', workCenters], () =>
    axios.get(`${Config.baseApiUrl}/WorkCentersFromCode${queryString}`).then((resp) => resp.data),
    {
      enabled: workCenters.length > 0
    },
  );

  const autoCompleteOptions: AutoCompleteValues[] | undefined = data?.map((item) => ({
    id: item.code ?? "",
    name: item.name ?? "",
    code: item.code ?? ""
  }));

  return { data: autoCompleteOptions, ...rest };
};

export const useUpdateOperationAssignedUsers = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError<Problem>, Assignment>(
    (assignment: Assignment) => axios.patch(`${Config.baseApiUrl}/assignments/${assignment.id}`, assignment),
    {
      onSuccess: (_, assignment) => {
        queryClient.invalidateQueries(["Team", assignment.teamId]);
      },
    }
  );
};

export const useFetchCompanyCodes = () => {
  return useQuery<AutoCompleteValues[], AxiosError>(['companyCodes'], () =>
    axios.get(`${Config.baseApiUrl}/companycode`).then((resp) => resp.data),
  );
};

