import { Route, Routes, useNavigate } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
import { Layout } from './components';
import {
  WorkAssignmentPage,
  TeamsPage,
  SettingsPage,
} from './pages';
import Config from './config';
import { loginRequest } from 'authConfig';
import { ROUTES } from './constants';
import queryClient from 'components/Common/queryClient';
import {
  AuthenticatedTemplate,
  useAccount,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { useEffect } from 'react';
import {
  DisplayNameAtom,
  UserNameAtom,
  OwnerIdAtom,
  PersonIdAtom,
  UserAtom,
  userGroupRoleAtom,
} from 'Atoms/AuthAtoms';
import { useAtom, useSetAtom } from 'jotai';
import { formatName, getValueFromObject } from 'utilities/StringUtil';
import { fetchOwner } from 'hooks/CommonHooks';
import { QueryClientProvider } from 'react-query';
import { mapGroupToRole } from 'utilities/mapGroupToRole';
import UnAuthorizedAccess from 'components/Common/UnAuthorisedAccess';
import { selectedLanguageAtom } from 'Atoms/CommonAtoms';
import { useTranslation } from 'react-i18next';
import { companyCodeAtom } from 'Atoms/SettingsAtom';

LicenseInfo.setLicenseKey(Config.MUILicenseKey);

// Import statements...

const App = () => {
  const [, setDisplayName] = useAtom(DisplayNameAtom);
  const [, setUserName] = useAtom(UserNameAtom);
  const [, setPersonId] = useAtom(PersonIdAtom);
  const [, setOwner] = useAtom(OwnerIdAtom);
  const [, setUser] = useAtom(UserAtom);
  const [companyCode, setCompanyCode] = useAtom(companyCodeAtom);
  const [_, setSelectedLanguage] = useAtom(selectedLanguageAtom);

  const [userGroupRole, setUserGroupRole] = useAtom(userGroupRoleAtom);
  const { login } = useMsalAuthentication(
    InteractionType.Redirect,
    loginRequest
  );
  const navigate = useNavigate();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { i18n } = useTranslation();


  useEffect(() => {
    setSelectedLanguage(i18n.resolvedLanguage ?? 'en');

    const displayName = formatName(account?.name ?? '');
    setDisplayName(displayName);
    if (account?.idTokenClaims != null) {
      const personId = getValueFromObject(account?.idTokenClaims, 'PersonID');
      const userName = getValueFromObject(account, 'username');
      const groups = getValueFromObject(account?.idTokenClaims, 'groups');
      const mappedRole = mapGroupToRole(groups);
      setUserName(userName);
      setPersonId(personId);
      setUserGroupRole(mappedRole);    
      const fetchOwnerAfterAuth = async () => {
        try {
          const owner = await fetchOwner(personId, userName, displayName);
          setUser(owner);          
          setCompanyCode(owner.companyCode);
          const ownerIdInt = owner.id;
          setOwner(ownerIdInt);
        } catch (error) {
          // Handle error...
        }
      };
      fetchOwnerAfterAuth();
      navigate('/workassignment');
    }
  }, [account, setDisplayName, setOwner, setPersonId]);
      
  return (
    <QueryClientProvider client={queryClient}>
      <AuthenticatedTemplate>
        {userGroupRole.includes(Config.Supervisor) ? (
          <Layout>
            <Routes>
              <Route path={ROUTES.workassignment} element={<WorkAssignmentPage />} />
              <Route path={ROUTES.teams} element={<TeamsPage />} />
              <Route path={ROUTES.settings} element={<SettingsPage />} />
            </Routes>
          </Layout>
        ) : (
          <UnAuthorizedAccess></UnAuthorizedAccess>
        )}
      </AuthenticatedTemplate>
    </QueryClientProvider>
  );
};

export default App;
