import { Box, Button, Grid, Snackbar, Typography } from "@mui/material";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";

// Hooks
import {
  useFetchMaintenancePlants,
  useFetchPlanningPlants,
  useFetchWorkCenters,
} from "hooks/WorkAssignmentHooks";

import { OwnerIdAtom, UserAtom } from "Atoms/AuthAtoms";
import PreselectedAutocomplete from "components/Common/PreSelectedAutocomplete";
import { useTranslation } from "react-i18next";
import { SettingKey } from "constants/SettingKey";
import {
  useDeleteUserSettings,
  useFetchsettings,
  useInsertSettings,
} from "hooks/SettingHooks";
import { AutoCompleteValues } from "types/AutoCompleteValues";
import {
  findAddedSettings,
  findRemovedSettings,
} from "utilities/SettingsUtil";
import { getMatchingValues } from "utilities/StringUtil";
import PreSelectedAutocompleteSelectAll from "components/Common/PreSelectedAutocompleteSelectAll";
import DevSettings from "./DevSettings";
import { companyCodeAtom } from "Atoms/SettingsAtom";
import { isProd } from '../../utilities/envConfig';

const ViewSettings = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [OwnerId,] = useAtom(OwnerIdAtom);
  const [User,] = useAtom(UserAtom);
  const [companyCode, ] = useAtom(companyCodeAtom);
  const [t, ] = useTranslation();
  const {
    data: PlanningPlantsettings,
    isLoading: isPPSettingsLoading,
  } = useFetchsettings(OwnerId, SettingKey.PlanningPlant);
  const {
    data: MaintPlantsettings,
    isLoading: isMPSettingsLoading,
  } = useFetchsettings(OwnerId, SettingKey.MaintenancePlant);
  const {
    data: workcenterssettings,
    isLoading: isWCSettingsLoading,
  } = useFetchsettings(OwnerId, SettingKey.WorkCenters);
  const [selectedPlanningPlants, setSelectedPlanningPlants] = useState<
    Array<AutoCompleteValues>
  >([]);
  const [selectedMaintPlants, setselectedMaintPlants] = useState<
    Array<AutoCompleteValues>
  >([]);
  const [selectedWorkCenters, setSelectedWorkCenters] = useState<
    Array<AutoCompleteValues>
  >([]);
  const insertSettingsMutation = useInsertSettings();
  const deletetSettingsMutation = useDeleteUserSettings();

  // Fetch planning plants and maintenance plants based on selected planning plants
  const {
    data: planningPlants,
    isLoading: isPlanningPlantLoading,
  } = useFetchPlanningPlants(companyCode);
  
  const {
    data: MaintenancePlants,
    isLoading: isMaintPlantLoading,
  } = useFetchMaintenancePlants(
    selectedPlanningPlants
      .map(({ planningPlantId }) => planningPlantId)
      .filter((id): id is string => id !== undefined)
  );

  // Fetch work centers based on selected planning plants and maintenance plants
  const {
    data: workCenters,
    isLoading: isWorkCenterLoading,
  } = useFetchWorkCenters(
    selectedPlanningPlants
      .map(({ planningPlantId }) => planningPlantId)
      .filter((id): id is string => id !== undefined)  );

  useEffect(() => {
    if (PlanningPlantsettings && PlanningPlantsettings.length > 0) {
      let selectedPP = getMatchingValues(
        planningPlants ?? [],
        PlanningPlantsettings.map(({ settingValue }) => settingValue)
      );
      setSelectedPlanningPlants(selectedPP);
    }
  }, [PlanningPlantsettings, isPPSettingsLoading, planningPlants]);

  useEffect(() => {
    if (MaintPlantsettings && MaintPlantsettings.length > 0) {
      let selectedMP = getMatchingValues(
        MaintenancePlants ?? [],
        MaintPlantsettings.map(({ settingValue }) => settingValue)
      );
      setselectedMaintPlants(selectedMP);
    }
  }, [MaintPlantsettings, MaintenancePlants, isMPSettingsLoading]);

  useEffect(() => {
    if (workcenterssettings && workcenterssettings.length > 0) {
      let selectedWC = getMatchingValues(
        workCenters ?? [],
        workcenterssettings.map(({ settingValue }) => settingValue)
      );
      setSelectedWorkCenters(selectedWC);
    }
  }, [workcenterssettings, isWCSettingsLoading, workCenters]);

  const handleSaveChanges = () => {
    const addedPlanningPlants = findAddedSettings(
      PlanningPlantsettings ?? [],
      selectedPlanningPlants.map(({ id }) => id),
      SettingKey.PlanningPlant,
      OwnerId
    );
    const removedPlanningPlants = findRemovedSettings(
      PlanningPlantsettings ?? [],
      selectedPlanningPlants.map(({ id }) => id)
    );

    const addedMaintenancePlants = findAddedSettings(
      MaintPlantsettings ?? [],
      selectedMaintPlants.map(({ id }) => id),
      SettingKey.MaintenancePlant,
      OwnerId
    );
    const removedMaintenancePlants = findRemovedSettings(
      MaintPlantsettings ?? [],
      selectedMaintPlants.map(({ id }) => id)
    );

    const addedWorkCenters = findAddedSettings(
      workcenterssettings ?? [],
      selectedWorkCenters.map(({ id }) => id),
      SettingKey.WorkCenters,
      OwnerId
    );
    const removedWorkCenters = findRemovedSettings(
      workcenterssettings ?? [],
      selectedWorkCenters.map(({ id }) => id)
    );

    // Perform insert and delete operations with the respective mutation functions

    // Insert settings
    const CombinedInsertSettings = addedPlanningPlants.concat(
      addedMaintenancePlants,
      addedWorkCenters
    );
    insertSettingsMutation.mutate(CombinedInsertSettings);

    const CombinedDeleteSettings = removedPlanningPlants.concat(
      removedMaintenancePlants,
      removedWorkCenters
    );
    deletetSettingsMutation.mutate(CombinedDeleteSettings);
    setOpenSnackbar(true);
  };

  if (isPPSettingsLoading || isMPSettingsLoading || isWCSettingsLoading) {
    return <div>{t("LoadingText")}</div>;
  }

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        message={t('SettingsSucessText')}
      />
      <Grid container marginTop={1}>
        <Grid item xs={12} md={10} marginBottom={2}>
          <Typography variant="subtitle1" fontWeight={600}>
          {t("WorkAssignmentSettingsText")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={10} marginBottom={2}>
          <Typography variant="body1">{t("SearchDefaultsText")}</Typography>
        </Grid>
        <Grid container spacing={2} sx={{ height: 500, maxHeight: 500, overflow: 'auto' }}>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <Box>

              <Box sx={{ marginBottom: 4 }}>
                <PreselectedAutocomplete
                  options={planningPlants ?? []}
                  label={t("PlanningPlantText")+ "(s)"}
                  onChange={setSelectedPlanningPlants}
                  selectedValues={selectedPlanningPlants}
                  required={false}
                  isConcateText={true}
                  isLoading={isPlanningPlantLoading}
                  useZIndex={true}
                />
              </Box>

              <Box sx={{ marginBottom: 4 }}>
                <PreSelectedAutocompleteSelectAll
                  options={workCenters ?? []}
                  label={t("WorkCentresText")}
                  onChange={setSelectedWorkCenters}
                  selectedValues={selectedWorkCenters}
                  required={false}
                  isConcateText={true}
                  isLoading={isWorkCenterLoading}
                  renderCode={true}
                  useZIndex={true}
                />
              </Box>
              <Box sx={{ marginBottom: 4 }}>
                <PreselectedAutocomplete
                  options={MaintenancePlants ?? []}
                  label={t("MaintenancePlantsText")}
                  onChange={setselectedMaintPlants}
                  selectedValues={selectedMaintPlants}
                  required={false}
                  isConcateText={true}
                  isLoading={isMaintPlantLoading}
                  useZIndex={true}
                />
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveChanges}
                sx={{ width: '150px' }}
              >
                {t("SaveChangesCapitalisedText")}
              </Button>
            </Box>
            {isProd ? null : <DevSettings />}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewSettings;
