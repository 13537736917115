
import MuiDropdown from "components/WorkAssignmnetGrid/MuiDropDown";
import { Box, CircularProgress, Typography } from "@mui/material";
import { companyCodeAtom } from "Atoms/SettingsAtom";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import {
  useFetchCompanyCodes,
} from "hooks/WorkAssignmentHooks";

const DevSettings = () => {
  const [t,] = useTranslation();
  const [companyCode, setCompanyCode] = useAtom(companyCodeAtom);



  const { data: companyCodes, isLoading } = useFetchCompanyCodes();




  return (

    <Box mt={15} sx={{ marginBottom: 4 }}>
      <Typography my={2} variant="h6" marginTop={2}>
        Dev Settings
      </Typography>

      {isLoading ? <CircularProgress />
        : <MuiDropdown
          options={companyCodes ?? []}
          label={t("companyCodeTxt")}
          onChange={(e) => setCompanyCode(e)}
          selectedValue={companyCode}
          required={false}
          includeIdInLabel
        />}
    </Box>
  )
}
export default DevSettings